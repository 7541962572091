import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faPaperPlane, faPhone, faPhoneAlt, faPhoneSlash, faPhoneSquare, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { isProduction } from '../core/common.utils';
import { ApiService, ContactFormSendable, ContactFormSendResponse } from '../shared/service/api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @ViewChild('modalSubmitFormWasFinished', { static: false }) modalSubmitFormWasFinished;

  public showLoadingIndicator: boolean = false;

  public modalModel = {
    title: '',
    image: '',
    message: ''
  }

  icons = {
    paperPlane: faPaperPlane,
    phone: faPhone,
  }


  public purposes: string[] = [
    'Development of an iOS or web app',
    'Design of an iOS or web app',
    'Swift Training for iOS development',
    'Some other exciting request'
  ];

  public contactForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.resetContactFormForProdOrDev();
  }

	onSubmit() {
		if (this.contactForm.valid) {
      const contactFormSendable: ContactFormSendable = {
        name: this.contactForm.value.full_name,
        email: this.contactForm.value.email,
        phone: this.contactForm.value.phone_number,
        purpose: this.contactForm.value.purpose,
        message: this.contactForm.value.message
      }
      this.sendContactForm(contactFormSendable);
	  } else {
      // TODO: Show data is not valid info.
    }
  }

  private resetContactForm() {
    this.contactForm = this.formBuilder.group({
      full_name: this.createFormControl('', [Validators.required]),
      email: this.createFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
      ])),
      phone_number: this.createFormControl('', [Validators.required]),
      purpose: this.createFormControl(this.purposes[0], []),
      message: this.createFormControl('', [])
    });
  }

  private resetContactFormDevDefault() {
    this.contactForm = this.formBuilder.group({
      full_name: this.createFormControl('Max Mustermann', [Validators.required]),
      email: this.createFormControl('max.mustermann@helloworld.com', [Validators.required, Validators.email]),
      phone_number: this.createFormControl(555123456789, [Validators.required]),
      purpose: this.createFormControl(this.purposes[0], []),
      message: this.createFormControl('Das ist eine Testnachricht. Hallo Welt.', [])
    });
  }

  private sendContactForm(contactFormSendable: ContactFormSendable) {
    this.showLoadingIndicator = true;

    // Simulate Delay of 1.5s and submit form to email
    setTimeout(_ => {
      this.apiService.sendContactFormViaEmail(contactFormSendable)
        .subscribe(
          contactFormSendResponse => this.handleSubmitSuccess(contactFormSendResponse),
          error => this.handleSubmitFailure(error)
        );
    }, 1500);
  }

  private handleSubmitSuccess(contactFormSendResponse: ContactFormSendResponse) {
    console.log(contactFormSendResponse);
    this.showLoadingIndicator = false

    this.prepareModalModel(true);
    this.openModal(this.modalSubmitFormWasFinished, true);
  }

  private handleSubmitFailure(error: Error) {
    console.log(error);
    this.showLoadingIndicator = false

    this.prepareModalModel(false);
    this.openModal(this.modalSubmitFormWasFinished, false);
  }

  private prepareModalModel(succeeded: boolean) {
    if (succeeded === true) {
      this.modalModel.title = `Thank you, ${this.contactForm.value.full_name}!`;
      this.modalModel.message = `Your message regarding <strong>${this.contactForm.value.purpose}</strong> was sent to me. I am excited about it. \nYou will get an answer as soon as possible. 😎`;
    } else {
      this.modalModel.title = `Oops! 🐜`;
      this.modalModel.message = `Your message regarding <strong>${this.contactForm.value.purpose}</strong> couldn\'t be sent, cause of a technical problem. \nPlease try again.<br><br>If the problems still exists, please call me directly by <br><a href="tel:+491734172334">+49 173 4172334</a>.`;
    }
  }

  private openModal(modal: any, succeeded: boolean) {
    const modalOptions: NgbModalOptions = {
      centered: true,
      size: 'lg'
    };

    const modalRef = this.modalService.open(modal, modalOptions);

    modalRef.result.then(fulfilledReason => {
      if (succeeded === true) {
        this.resetContactFormForProdOrDev();
      }
    }, rejectedReason => {
      if (succeeded === true) {
        this.resetContactFormForProdOrDev();
      }
    });
  }

  private resetContactFormForProdOrDev() {
    if (isProduction) {
      this.resetContactForm();
    } else {
      this.resetContactFormDevDefault();
    }
  }

  // Helper function to initialize form control values based on the environment
  private createFormControl(defaultValue, validators, isProd = isProduction()) {
    return new UntypedFormControl(isProd ? '' : defaultValue, validators);
  }
}
