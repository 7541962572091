import { Testimonial } from '../../model/model.vo';

export const testimonials: Testimonial[] = [
  {
    author: 'Pantea Kock',
    jobRole: 'Senior Product Owner',
    company: 'DHC Digital HealthCare-Systems GmbH',
    imageSrc: '../../assets/images/pantea-kock.webp',
    imageAlt: 'Pantea Kock Portrait',
    imageTitle: 'Pantea Kock',
    textHighlight: 'Sehr angenehm und produktiv',
    text: 'Die Zusammenarbeit mit Felix war stets sehr angenehm und produktiv. Den meisten Mehrwert hatten für mich die Geschwindigkeit und Qualität seiner Arbeit sowie seine wertvollen technischen Hinweise und Design-/Usability-Vorschläge. Durch die zügige Erledigung seiner Aufgaben bestand häufig die Möglichkeit, optionale Verbesserungen umzusetzen. Er machte proaktiv Optimierungsvorschläge und beteiligte sich maßgeblich an einer nachhaltigen Technologieauswahl. Das Business hat von der Zusammenarbeit mit Felix profitiert, indem er seine Expertise als iOS-Developer stets in Diskussionen mit Stakeholdern eingebracht hat. Darüber hinaus verdeutlichte er mir, welche Besonderheiten im iOS-Umfeld aus Zielgruppensicht relevant sind. Wir konnten mit seiner Mithilfe die im Projekt gesetzten KPIs bzgl. Qualität und Zeit erfüllen. Ich danke Felix für die wertvolle Unterstützung und Zusammenarbeit und wünsche ihm weiterhin viel Erfolg.'
  },
  {
    author: 'Oskar Bader',
    jobRole: 'Data Driven UX Designer & Growth Hacker',
    company: 'DesignWithValue',
    profileUrl: 'http://designwithvalue.com',
    imageSrc: 'https://www.xing.com/image/a_0_b_c0eb7c178_27557483_3/oskar-bader-foto.256x256.jpg',
    imageAlt: 'Oskar Bader Portrait',
    imageTitle: 'Oskar Bader',
    textHighlight: 'iOS Developer mit Blick fürs Ganze und Detail',
    text: 'Ich habe mit Felix in der Metafinanz in den letzten 2 Jahren intensiv zusammengearbeitet. Als Business Designer bin ich regelmäßig im Austausch mit Developern, allerdings hat mich Felix mit seinem Know-How zum Thema User Experience sowie User Interface Design immer wieder überrascht. Mit Felix war es möglich, im Dialog neue Ansätze und Ideen zu generieren, um so für die Zielgruppe eine passende und funktionierende Lösung zu finden.   Felix ist iOS Developer mit Blick fürs Ganze und dem Anspruch, Bestmögliches zu erreichen. Besonders zu schätzen gelernt habe ich, dass sich Felix nicht zu Schade ist, Neues zu lernen und die notwendige Zeit zu investieren, um dieses Ergebnis auch zu erreichen. Es ist unter anderem Felix zu Verdanken, dass die gesetzten KPIs erreicht wurden.  Die offene und umgängliche Art von Felix hat auch bei Münchens Regenwetter positiv zur Stimmung und der Dynamik im Team beigetragen. Auf die gemeinsame Arbeit im Projekt blicke ich sehr positiv zurück und hoffe, dass sich auch in Zukunft wieder eine Zusammenarbeit ergibt!  Danke!'
  },
  {
    author: 'Roberto Miranda González',
    jobRole: 'CEO',
    company: 'Native Solutions UG',
    profileUrl: 'https://de.linkedin.com/in/romiragon',
    imageSrc: '../../assets/images/roberto-miranda-gonzalez@0.25x.webp',
    imageAlt: 'Roberto Miranda González Portrait',
    imageTitle: 'Roberto Miranda González',
    textHighlight: 'Extremely quick learner',
    text: '"Extremely quick learner" is the first thought I have when I remember working with Felix. \n\nI had the pleasure of working with Felix at metafinanz, collaborating on the Pillenfee project. We developed together the iOS app. In that app, Felix adapted to working with reactive programming (RxSwift) remarkably fast. \n\nHe also shown a great interest and performance on code reviews. Also didn\'t take long for him to get used to our customized development process, in which tools as git-flow and a bi-weekly SCRUM approach with JIRA where used. Therefore both development velocity and code quality (though strict code reviews), quickly raised as he was working with us. \n\nFrom a character perspective, I would name Felix a team binder. He has the ability to establish harmony and unite a team through his warm and friendly character. Besides that, jokes and laughs are good part of any room where he is in. \n\›As a team member or a leader on any iOS project, Felix earns my highest recommendation.'
  },
  {
    author: 'Thomas Reinberger',
    jobRole: 'Scrum Master',
    company: 'metafinanz Informationssysteme GmbH',
    profileUrl: 'https://de.linkedin.com/in/thomas-reinberger-b92b4051',
    imageSrc: 'https://www.xing.com/image/6_2_0_836e2f8cd_3305125_7/thomas-reinberger-foto.256x256.jpg',
    imageAlt: 'Thomas Reinberger Portrait',
    imageTitle: 'Thomas Reinberger',
    textHighlight: 'Very appreciated colleague',
    text: 'I enjoy Felix\' tenicity concerning brainteasers that pop up during work as well as his friendlyness and accurancy. During the last years he became not only a very appreciated colleague but also a friend.',
  },
  {
    author: 'Patrick Stoica',
    jobRole: 'Geschäftsführender Gesellschafter',
    company: 'Grey Rook GmbH',
    profileUrl: 'https://www.linkedin.com/in/patrick-stoica-09a6babb/',
    imageSrc: 'https://www.2bic.de/wp-content/uploads/patrick-stoika-350x350.jpg',
    imageAlt: 'Patrick Stoica Portrait',
    imageTitle: 'Patrick Stoica',
    textHighlight: 'Seine Leistungen waren ein wichtiger Bestandteil für den Erfolg des Projekts.',
    text: 'Ich habe Felix in einem gemeinsamen Projekt, der Entwicklung einer Web-Applikation, kennen und schätzen gelernt. Innerhalb des Programmierteams welches in einem agilen Framework agierte, hatte er die Rolle des Designers und Frontend-Entwicklers inne. Seine Leistungen waren sehr gut und ein wichtiger Bestandteil für den Erfolg des Projekts. Felix half sowohl dem Team als auch dem Kunden, Ideen zu durchdenken, zu verbessern und konkret zu visualisieren. Seine Erfahrung, gutes Gespür für Design und den effektiven UX Flow hat er mit seiner konstruktiven und positiven Art wertvoll einbringen können. Ich empfehle Felix als kommunikationsstarken Fachexperten weiter!'
  },
  {
    author: 'Tobias Donder',
    jobRole: 'Platform Lead iOS',
    company: 'Techniker Krankenkasse',
    profileUrl: 'https://www.linkedin.com/in/tobias-donder-65291521',
    imageSrc: 'https://www.th-luebeck.de/fileadmin/media/02_Studium/02_Studienberatung/Erfahrungsberichte/Tobias_Donder.jpg',
    imageAlt: 'Tobias Donder Portrait',
    imageTitle: 'Tobias Donder',
    textHighlight: 'Hat geholfen TK-Safe zu der erfolgreichsten Elektronischen Patientenakte auf dem deutschen Markt zu machen.',
    text: 'Felix hat unser iOS-Entwicklerteam mit seiner offenen und freundlichen Art für zwei Jahre vom ersten Tag an bereichert. Als iOS Developer ist er in seinem Fachgebiet stark unterwegs. Aktiv beflügelt er mit neuen Anregungen den fachlichen Austausch. Code-Reviews durch mind. zwei Entwickler, eine Minimum Unit-Test-Coverage von 95% und ein komplexes Projekt an sich haben Felix zu Höchstform auflaufen lassen. Nicht zuletzt hat uns seine langjährige Erfahrung in der eHealth- und MedTech-Branche geholfen, TK-Safe zu der erfolgreichsten Elektronischen Patientenakte auf dem deutschen Markt zu machen. Wir sind sehr glücklich über die Zusammenarbeit und freuen uns, wenn wir in Zukunft Felix wieder für unser Projekt engagieren dürfen.'
  },
  {
    author: 'Max Tilleßen',
    jobRole: 'Digital Health Expert, stlv. PO',
    company: 'Techniker Krankenkasse',
    profileUrl: 'https://www.linkedin.com/in/maxtillessen',
    imageSrc: '../../assets/images/max-tillessen@0.5x.webp',
    imageAlt: 'Max Tilleßen Portrait',
    imageTitle: 'Max Tilleßen',
    textHighlight: 'Ausgezeichnetes technisches Verständnis',
    text: 'Felix war für mich ab dem ersten Tag unserer Zusammenarbeit für die digitale Patientenakte der Techniker Krankenkasse stets eine große Hilfe. Durch seine sehr gute Übersicht und sein ausgezeichnetes technisches Verständnis hat er zu großen Teilen dazu beigetragen, die Projektentwicklung voranzutreiben. Bei der Entwicklung neuer Features habe ich mich gerne mit Felix ausgetauscht, da er fachliche und technische Inhalte sehr gut miteinander verbinden kann und dadurch viele wertvolle Informationen und Ideen liefert. Auch auf persönlicher Ebene ist der Umgang mit Felix super angenehm. Er trägt oft zur guten Stimmung im Team bei und geht auf jedes Teammitglied proaktiv zu. Mir hat die Zusammenarbeit mit Felix sehr viel Spaß gemacht und würde mich darüber freuen, ihn auch in Zukunft noch mal im Team haben zu dürfen.'
  },
  {
    author: 'Karsten Kleinert',
    jobRole: 'Online Konzepter / User Experience Designer',
    company: 'Techniker Krankenkasse',
    profileUrl: 'https://www.linkedin.com/in/karsten-kleinert-40a87a92',
    imageSrc: '../../assets/images/karsten-kleinert@0.25x.webp',
    imageAlt: 'Karsten Kleinert Portrait',
    imageTitle: 'Karsten Kleinert',
    textHighlight: 'Eine Bereicherung für das Team',
    text: 'Felix habe ich als kompetenten, kommunikationsstarken und innovativen Kollegen schätzen gelernt. Er konnte seine Ideen, auch außerhalb der Profession, stets schnell und verständlich vermitteln. Die gemeinsamen Diskussionen waren immer lösungsorientiert und wertschätzend. Seine Person und seine Arbeitsweise waren eine Bereicherung fürs Team. Vielen Dank lieber Felix für die Zusammenarbeit und hoffentlich bis bald!'
  },
  {
    author: 'Frank Lehmann',
    jobRole: 'Senior iOS Developer',
    company: 'Frank Lehmann Mobile Apps & Consulting',
    profileUrl: 'https://heyfrank.de',
    imageSrc: '../../assets/images/frank-lehmann@0.25x.webp',
    imageAlt: 'Frank Lehmann Portrait',
    imageTitle: 'Frank Lehmann',
    textHighlight: 'Felix\'s technical expertise enabled the client to meet their deadlines',
    text: 'I was part of an 80-person Scrum team with Felix at IBM / Techniker Krankenkasse for the development of the Electronic Health Record (ePA) in the area of iOS development. Felix\'s technical expertise enabled the client to meet their deadlines while increasing code quality.'
  },
  {
    author: 'Omar Ahmed',
    jobRole: 'Senior iOS Developer',
    company: 'SAP SE',
    profileUrl: 'https://www.linkedin.com/in/0mar1/',
    imageSrc: '../../assets/images/omar-ahmed@0.25x.webp',
    imageAlt: 'Omar Ahmed Portrait',
    imageTitle: 'Omar Ahmed',
    textHighlight: 'Very professional and easy to communicate with.',
    text: 'Great engineering practices, very good code quality and quick clear communications. Very recommended!'
  },
  {
    author: 'Naveed Khalid',
    jobRole: 'Lead iOS Developer',
    company: 'SAP SE',
    profileUrl: 'https://www.linkedin.com/in/itsnaveedhere/',
    imageSrc: '../../assets/images/naveed-khalid@0.25x.webp',
    imageAlt: 'Naveed Khalid Portrait',
    imageTitle: 'Naveed Khalid',
    textHighlight: 'I would recommend him any day as he is an excellent human being.',
    text: 'Felix worked for SAP on a large scale iOS app in the area of public health. The app was targeted to millions of people. He is a very sound iOS developer with a strong command on latest iOS stack. He is an easy-to-go person and always tries to come up without of the box solutions that is really helpful to the project. I would recommend him any day as he is an excellent human being as well.'
  },
  {
    author: 'Wolfgang Kluth',
    jobRole: 'Senior iOS Engineer',
    company: 'Self Employed',
    profileUrl: 'https://wolfgangkluth.eu',
    imageSrc: '../../assets/images/wolfgang-kluth@2x.webp',
    imageAlt: 'Wolfgang Kluth Portrait',
    imageTitle: 'Wolfgang Kluth',
    textHighlight: 'In jedem iOS-Entwicklerteam eine wertvolle Bereicherung',
    text: 'Ich kann Felix Schmidt uneingeschränkt als iOS Entwickler empfehlen. Wir haben zusammen an der elektronischen Patientenakte (ePA) bei der Techniker Krankenkasse gearbeitet und ich war beeindruckt von seinem tiefen Verständnis für Swift, SwiftUI und Softwarearchitekturen. Felix zeichnet sich besonders durch seine Fähigkeit aus, als Teamplayer zu arbeiten. Er war immer bereit, seine Expertise zu teilen und hat sich intensiv um die Zusammenarbeit mit anderen Entwicklern, Designern und Projektmanagern bemüht. Seine Fähigkeit, komplexe Probleme zu lösen und innovative Lösungen zu entwickeln, hat unser Team oft beeindruckt. Felix hat auch ein starkes Interesse an den neuesten Trends und Technologien im Bereich iOS-Entwicklung und ist immer bereit, neue Werkzeuge und Frameworks zu erlernen und einzusetzen. Ich bin sicher, dass Felix in jedem iOS-Entwicklerteam eine wertvolle Bereicherung darstellen würde. Seine Kenntnisse in den Bereichen UI/UX-Design, API-Integration und Testautomatisierung machen ihn zu einem besonders wertvollen Kandidaten. Ich kann Felix als einen äußerst zuverlässigen, motivierten und erfahrenen iOS-Entwickler empfehlen, der sich mit Leidenschaft für qualitativ hochwertige und benutzerfreundliche Anwendungen einsetzt.',
  },
    {
    author: 'Nikolaus Schauersberger',
    jobRole: 'Senior Mobile Engineer',
    company: 'Schauersberger Softwaredesign',
    profileUrl: 'https://www.linkedin.com/in/nikolaus-schauersberger/',
    imageSrc: '../../assets/images/nikolaus-schauersberger@2x.webp',
    imageAlt: 'Nikolaus Schauersberger Portrait',
    imageTitle: 'Nikolaus Schauersberger',
    textHighlight: 'A standout developer!',
    text: 'I strongly recommend Felix, a versatile and adaptable software developer I\'ve had the pleasure of collaborating with on two projects. In our first project, Felix showcased impressive proficiency in native iOS development which is his main domain. He contributed to our success with his high-quality, efficient and user-centric applications. Our second project, an Angular web app, was a new frontier for Felix. Nevertheless, he quickly grasped Angulars nuances and crafted a superb application, demonstrating his ability to learn and adapt swiftly. Throughout both projects, Felix\'s willingness to assist, share knowledge, and foster positive team dynamics was commendable. His combination of technical expertise, quick learning, and collaboration makes him a standout developer. I endorse Felix for any project, needing a capable, adaptable, and team-oriented software developer.',
  },
  {
    author: 'Thomas Podewils',
    jobRole: 'Senior iOS Engineer',
    company: 'TP Thomas Podewils IT-Consulting',
    profileUrl: 'https://www.linkedin.com/in/thomas-podewils-8995a3190',
    imageSrc: '../../assets/images/testimonials/thomas-podewils.webp',
    imageAlt: 'Thomas Podewilsr Portrait',
    imageTitle: 'Thomas Podewils',
    textHighlight: 'Über den Tellerand hinaus',
    text: 'Ich habe mit Felix in mehreren Banking-Projekten zusammengearbeitet. Fachlich und menschlich ist man bei Ihm sehr gut aufgehoben. Durch seine langjährige Erfahrung hat er einen sehr guten analytischen Blick für die Anforderungen des Kunden, auch über den Tellerrand hinaus.',
  },
];
