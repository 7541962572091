<section class="container
                my-7">
  <div class="row">

    <div class="col-12 col-lg-6
                order-last order-lg-first
                d-flex flex-column
                justify-content-center align-items-center
                slide-in-from-left">
      <div class="pt-6 pt-lg-0">
        <h1 class="display-4 text-dark"><strong class="fw-bold">About</strong> Felix.</h1>
        <p class="lead mt-1">Freelance software engineer, based in Switzerland, but available worldwide.</p>

        <div class="pt-3">
          <p>“Felix Schmidt is a Senior Software Engineer specialising in mobile development for iOS and web frontends. He specialises in long-term and complex app projects, preferably in the insurance, healthcare and financial sectors. The software projects he has worked on serve over 10 million users worldwide.</p>
          <p>With a degree in Business Computer Science and 15 years of experience, he is characterised by working with clients such as SAP, Allianz or Commerzbank.</p>
        </div>

        <div class="pt-4">
          <p>Please find next my <strong>CV</strong> to download.</p>
          <app-cv-download-button [btnClass]="'btn btn-dark btn-fs-large'"></app-cv-download-button>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6
                order-first order-lg-last
                slide-in-from-right">
      <img id="hero-image"
           src="../../assets/images/felix-schmidt-smiling-during-programming-an-ios-application.webp"
           alt="Felix Schmidt is smiling while iOS development" />
    </div>
  </div>
</section>

<section id="skills"
         class="inner-shadow
                bg-elevated-1-light
                py-7">
  <div class="text-center pb-4">
    <h2 class="display-5 fw-bold text-center">Skill Focus</h2>
    <p class="lead">Let's merge technology with design, <br>to craft your next digital mobile application.</p>
  </div>
  <div class="container">
    <div class="row">

      <div class="col-12 col-lg-4
                  order-2 order-lg-1">
        <h3 class="skill-focus">Development</h3>
        <h4 class="skill-sub-focus">iOS</h4>
        <ul class="fs-list">
          <li>Native iOS development with Swift for iPhone, iPad or Apple Watch</li>
          <li>UIKit, SwiftUI, Swift Data, Vision and other Apple libraries</li>
          <li>Reactive programming using Combine or RxSwift</li>
          <li>Apply MVVM, MVVM-C design patterns</li>
          <li>Implement RESTful APIs and define by Swagger/OpenAPI</li>
          <li>Ensure accessibility demands such as Dynamic Typing, Voice Over, ...</li>
          <li>Unit and UI testing with XCTest, TDD</li>
          <li>Develop with XCode, SPM or CocoaPods</li>
          <li>Continues Integration/Development on GitHub, Gitlab, Azure or Jenkins</li>
          <li>Test and release apps with Testflight and App Store Connect</li>
        </ul>
        <h4 class="skill-sub-focus">Web</h4>
        <ul class="fs-list">
          <li>Web development for mobile and desktop</li>
          <li>Using Angular, Typescript, JavaScript (ES6+), HTML, CSS/SASS</li>
          <li>Reactive programming using RxJS</li>
          <li>Backend integration with RESTful APIs or Firebase</li>
          <li>Integration of Web APIs</li>
          <li>Using CSS frameworks such as Angular Material or Bootstrap</li>
        </ul>
      </div>

      <div class="col-12 col-lg-4
                  order-1 order-lg-2
                  d-flex
                  justify-content-center align-items-center
                  py-4">
        <img class="skill-focus-pie-chart"
             src="../../assets/images/skill-focus-pie-chart.svg"
             alt="Skill focus as a pie chart">
      </div>

      <div class="col-12 col-lg-4
                  order-3">
        <h3 class="skill-focus">Design &amp; Usability</h3>
        <ul class="fs-list
                   mt-3">
          <li>UX design for mobile and web interfaces</li>
          <li>Responsive design, mobile-first</li>
          <li>Interactive prototypes with Figma</li>
          <li>Usability evaluations with user target grous</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section id="random-facts"
         class="inner-shadow
                fscolor-bg-elevated-3-light
                py-7">
  <div class="container">
    <div class="row">

      <div class="col-xs-12 col-lg-6
                  d-flex flex-column
                  justify-content-center align-items-center">
        <img class="rounded"
             src="../../assets/images/felix-schmidt-smiling-with-cup-behind-macbook.webp"
             loading="lazy"
             alt="Felix Schmidt is programming Swift and thinking with a coffee in his hands about a solution for the problem." />
      </div>

      <div class="col-xs-12 col-lg-6
                  pt-5
                  d-flex flex-column
                  justify-content-center align-items-stretch align-items-sm-center">
        <article class="text-center text-lg-left">
          <h2 class="text-dark">8<span class="fw-bold"> random facts</span></h2>
          <ul class="fs-list text-dark">
            <li>I love clean code.</li>
            <li>I love awesome usability.</li>
            <li>I love products from Apple (suprise!).</li>
            <li>I'm a bit of an order freak.</li>
            <li>I drink too much coffee while working.</li>
            <li>I am German, but I live in Switzerland.</li>
            <li>I'm grateful to share knowledge with others.</li>
            <li>I enjoy giving presentations.</li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</section>

<app-what-do-next [options]="{work: true, contact: true, socialConnect: true}"></app-what-do-next>
