<div class="container">
  <div class="row">
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
      <div class="text-centered-desktop-left">
        <h1 class="fs-headline">Contact</h1>
        <p class="lead mb-4">Get in touch with me via <br>phone, email or personally.</p>

        <div class="d-flex justify-content-center justify-content-lg-start align-items-center gap-3">
          <fa-icon [icon]="icons.phone" [size]="'2x'"></fa-icon>

          <div class="d-flex flex-column gap-1">
            <a href="tel:+491734172334"
               title="Call Felix Schmidt directly."
               aria-label="Call Felix Schmidt at plus four nine one seven three four one seven two three three four"
            >
             +41 (0) 78 444 39 44 🇨🇭
            </a>
            <a href="tel:+491734172334"
               title="Call Felix Schmidt directly."
               aria-label="Call Felix Schmidt at plus four nine one seven three four one seven two three three four"
            >
             +49 (0) 173 41 72 334 🇩🇪
            </a>

          </div>


        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
      <img class="rounded-image rounded-circle"
           src="../../assets/images/felix-schmidt-with-iphone-and-macbook-smiles-before-camin.webp"
           alt="Felix Schmidt with Apple iPhone and Apple MacBook smiles in front of a fireplace." />
    </div>
  </div>
</div>

<div class="container-fluid inner-shadow bg-elevated-1-light p-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center gap-3">
        <fa-icon [icon]="icons.paperPlane" [size]="'3x'"></fa-icon>
        <h2>Write me an email</h2>
      </div>
      <div class="col-12 col-lg-6">
        <form class="d-flex flex-column justify-content-between align-items-stretch gap-3"
              [formGroup]="contactForm"
              (ngSubmit)="onSubmit()">

          <div class="form-group">
            <label class="form-label small required">Name</label>
            <input type="text"
                   class="form-control"
                   formControlName="full_name"
                   placeholder="Enter name" />
          </div>

          <div class="form-group">
            <label class="form-label small required">Email</label>
            <input type="email"
                   class="form-control"
                   formControlName="email"
                   placeholder="Enter email"
                   [ngClass]="{'is-invalid': contactForm.controls.email.invalid && (contactForm.controls.email.dirty || contactForm.controls.email.touched)}" />
          </div>

          <div class="form-group">
            <label class="form-label small required">Phone</label>
            <input type="tel"
                   class="form-control"
                   formControlName="phone_number"
                   placeholder="+491234567890" />
          </div>

          <div class="form-group">
            <label class="form-label small">Service</label>
            <select class="form-control"
                    formControlName="purpose">
              @for(purpose of purposes; track purpose) {
                <option>{{ purpose }}</option>
              }
            </select>
          </div>

          <div class="form-group">
            <label class="form-label small">How can I help you?</label>
            <textarea class="form-control"
                      formControlName="message"
                      rows="5"
                      placeholder="Feel free to describe your request ..."></textarea>
          </div>

          <p class="mb-0"><small>Please take note of the <a routerLink="/imprint-and-data-privacy" class="text-decoration-none">privay policy</a>.</small></p>

          <div class="d-flex flex-column
                  align-items-center align-items-lg-start">
            <button [disabled]="!contactForm.valid"
                    type="submit"
                    class="btn btn-primary btn-fs-large float-right">
              <span [ngSwitch]="showLoadingIndicator">
                <span *ngSwitchCase="true">
                  <div class="spinner-grow spinner-grow-sm text-info"></div>
                  Submitting ...
                </span>
                <span *ngSwitchCase="false">Send email</span>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid inner-shadow office-background-image">
  <div class="container d-flex justify-content-center justify-content-md-end justify-content-lg-start align-items-center">
    <div class="row">
      <div class="col-12">
        <div class="card office-sisikon">
          <img src="../../assets/images/office-2.webp"
               alt="Office Desk Felix Schmidt for Software Development"
               class="card-img-top" />
          <div class="card-body">
            <h5 class="card-title">Office Vierwaldstättersee</h5>
            <h6 class="figure-caption">Felix Schmidt Software Engineering</h6>
            <address class="card-text">
              Bachmattstr. 3<br>
              6452 Sisikon<br>
              Switzerland<br>
            </address>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item py-3">
              <h5 class="h6">Opening Times</h5>
              <p>Mo-Fr | 10:00 - 16:00</p>
              <p class="mb-0"><small>Please ring <mark>Schmidt</mark> doorbell. I'll pick you up personally.</small></p>
            </li>
          </ul>
          <div class="card-body">
            <a href="https://maps.app.goo.gl/nB38gDEH6sqxnMTR9"
               title="Calculate a route to the office of Felix Schmidt Software Engineering using Google Maps."
               class="card-link">Route on Google Maps</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid inner-shadow bg-elevated-2-light">
  <div class="container py-4">
    <div class="row">
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center align-items-lg-end">
        <img class="rounded-image rounded-circle"
             src="../../assets/images/felix-schmidt-is-calling-client-in-front-of-frauenkirche-1.webp"
             alt="Felix Schmidt is calling a client in front of the Frauenkirche in Dresden." />
      </div>

      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center align-items-lg-start gap-2">
        <div class="text-centered-desktop-left">
          <h3 class="h1">One more thing</h3>
          <p>Thank you for your trust. <br> I will get back you soon. </p>
          <p class="h1 handwritten">Best, Felix!</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal when submit contact form -->
<ng-template #modalSubmitFormWasFinished
             let-modalSubmitFormWasFinished
             id="modalSubmitFormWasFinished">
  <div class="modal-header">
    <h3 class="modal-title"
                id="modal-basic-title">{{ modalModel.title }}</h3>
    <button type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modalSubmitFormWasFinished.dismiss('Cross click')"></button>
  </div>

  <div class="modal-body">
    <p class="text-center"
       [innerHTML]="modalModel.message">
    </p>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary btn-fs-medium"
            (click)="modalSubmitFormWasFinished.close()">Okay</button>
  </div>
</ng-template>
