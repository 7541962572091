<header class="landing container-fluid d-flex justify-content-center align-items-center">
  <div class="container d-flex flex-column justify-content-center align-items-center align-items-md-start gap-4">
    <h1 class="title slide-in-from-bottom">Software Engineering by <span class="name">Felix Schmidt</span></h1>
    <p class="subtitle">Mobile App Development<br>
      for iOS and Web<br>
      around Apple devices<br>
      such as iPhone or Watch.<br>
    </p>
    <div class="d-flex flex-column flex-sm-row gap-3">
      <button class="btn btn-primary btn-fs-large"
              type="button"
              routerLink="/contact">
        Get in touch
      </button>
      <button class="btn btn-outline-secondary btn-fs-large"
              type="button"
              routerLink="/work">
        Recent work
      </button>
    </div>
  </div>
</header>

<section id="who-i"
         class="who-i
                container-fluid
                my-7">
  <article class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-10 col-md-8">

        <article>
          <h2 class="headline"><strong>What</strong> I work.</h2>
          <p class="lead">As a senior software developer I create apps for iOS & web and design user interfaces with demand for superior user experience.</p>
          <p>To get things done, I develop native apps in <strong>Swift</strong>, web apps in <strong>Angular</strong>, preferably in an agile project setup according to <strong>SCRUM</strong>.</p>
          <button class="btn btn-outline-dark btn-fs-medium mt-3"
                  routerLink="/work">See my work in recent projects</button>
        </article>

        <article>
          <h2 class="headline"><strong>Who</strong> I work for.</h2>
          <p class="lead">I help small to large companies who want to create great Apps and Digital Products. All over the world.</p>
          <p>Since 2013 I am working in software development &amp; UX design in the health insurance, banking and healthcare industry &mdash; mongst of that well-known customers as Allianz, Techniker, SAP or Commerzbank and much more.</p>
          <p>Long-term collaboration with my customers is very important to me. It ensures mutual trust and a deep understanding of software projects, employees and corporate culture.</p>
        </article>
      </div>
    </div>
  </article>
</section>

<section class="bg-light inner-shadow">
  <div class="container py-7">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div class="text-center mb-5">
          <h2 class="display-5 text-dark"><strong class="fw-bold">Trusted</strong> by great clients.</h2>
          <p  #clientsLead class="lead">From the eHealth, health insurance or banking industry, among others.</p>
        </div>
        @defer (on viewport(clientsLead)) {
          <app-client-logos></app-client-logos>
        }
      </div>
    </div>
  </div>
</section>

<section class="bg-dark">
  <div class="container py-7 d-flex flex-column justify-content-center align-items-center">
    <div class="text-center mb-5">
      <h2 class="display-5 text-white">For <strong class="fw-bold">recruiters.</strong></h2>
      <p #forRecruitersLead class="lead text-info">Please find next my CV to download.</p>
    </div>
    @defer (on viewport(forRecruitersLead); prefetch on idle) {
      <app-cv-download-button></app-cv-download-button>
    }
  </div>
</section>

<section id="testimonials" class="container-fluid topic inner-shadow">
  <article class="container">
      <h2 class="display-5 text-center text-dark"><strong class="fw-bold">Honest</strong> voices.</h2>
      <app-testimonials></app-testimonials>
  </article>
</section>

<section id="about" class="container-fluid topic inner-shadow">
  <div class="container">

    <h2>Learn <strong class="fw-bold">About Me</strong> In 60 Seconds.</h2>
    <article class="row">

      <!-- Career Highlights -->

      <div class="col-xs-12 col-md-6">
        <h3>3 Career Highlights</h3>
        <ul class="facts">
          <li>
            For two years, I helped developing an iOS app for a big healthcare insurance company.
            It was targeted for up to 10 million users and earned over 250k five-star ratings out of a total 4.8/5-star rating.</li>
          <li>
            I've done my studies of Business Computer Science extra-occupational to my job in Munich, and graduated as best of my class.</li>
          <li>
            Some years ago, my team and I won the metafinanz Xcellence award with a private healthcare insurance web app.
            As winner's prize we got a business trip to San Francisco and the Silicon Valley.</li>
        </ul>
      </div>

      <!-- Fun Facts -->

      <div class="col-xs-12 col-md-6">
        <div class="headline-with-button">
          <h3 class="headline">{{ aboutMeFunFacts.length }} Random Fun Facts</h3>
          <button class="btn btn-fs-small"
                  (click)="shakeFunFacts()">surprise me</button>
        </div>
        <ul class="facts">
          <li *ngFor="let funFact of aboutMeFunFacts">{{ funFact }}</li>
        </ul>
      </div>

    </article>
  </div>
</section>

<app-what-do-next [options]="{about: true, work: true, contact: true, socialConnect: true}"></app-what-do-next>
